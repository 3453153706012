import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import Dashboard from "./Dashboard";
import Businesses from "./Businesses";
import SideBar from "./SideBar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import BusinessDetail from "./BusinessDetail";
import AuthLogin from "./AuthLogin";

const queryClient = new QueryClient();

const Layout = () => {
  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <Router>
          <SideBar />
          <Routes>
            <Route path="/login" element={<AuthLogin />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/businesses" element={<Businesses />} />
            <Route path="/businesses/:id" element={<BusinessDetail />} />
          </Routes>
        </Router>
      </QueryClientProvider>
    </div>
  );
};

export default Layout;
