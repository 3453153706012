import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import axios from "axios";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import { Chip, Rating } from "@mui/material";
import GoogleMapLocation from "./GoogleMapLocation";

import GalleryImages from "./GalleryImages";

import {
  FaGooglePlay,
  FaEnvelope,
  FaChrome,
  FaWhatsapp,
  FaImage,
  FaList,
  FaAward,
  FaPeriscope,
} from "react-icons/fa";

const BusinessDetail = () => {
  let { id } = useParams();

  const { isLoading, data: businessData } = useQuery("business", () => {
    return axios.get(`https://apis.explorebtk.com/api/v1/businesses/${id}`);
  });

  if (isLoading) {
    return <h1>...Loading</h1>;
  }
  console.log("Getting Data from API", businessData.data);
  const coverPic = businessData.data.gallery.find(
    (image) => image.cover === true
  );

  const renderBusinessTime = () => {
    const renderTime = businessData.data.openHours.map((time, indx) => {
      return (
        <div className="business-timings" key={indx}>
          <div className="business-opening">
            <div className="opening-day">{time.day}</div> {time.to} {time.from}
          </div>
        </div>
      );
    });
    return renderTime;
  };

  const renderTags = () => {
    const tags = businessData.data.tags.map((tag, index) => {
      return (
        <div className="tags-div" key={index}>
          <Chip label={tag} variant="outlined" key={index} />
        </div>
      );
    });
    return tags;
  };

  const renderReviews = () => {
    const reviews = businessData.data.reviews.map((review, index) => {
      return (
        <div className="reviews-div" key={index}>
          <Col lg={4} className="review-title">
            {" "}
            <h4>{review.owner.name}</h4> {review.title}
          </Col>
          <Col lg={12}> {review.description}</Col>
          <Col lg={4}>
            <Rating name="size-medium" defaultValue={review.rating} />
          </Col>
        </div>
      );
    });
    return reviews;
  };

  const renderReviewProfile = () => {
    const userReviews = businessData.data.reviews.map((review, index) => {
      return (
        <div className="avatar-main" key={Math.random()}>
          <img
            className="avatar" alt="avatar"
            src={
              review.owner.avatar
                ? review.owner.avatar
                : "../images/dumy-avatar.png"
            }
          />
        </div>
      );
    });
    return userReviews;
  };

  const renderFacilities = () => {
    const facilities = businessData.data.facilities.map((facility, indx) => {
      return (
        <div className="facilities" key={indx}>
          {facility.name}
          {/* <div>{facility.icon}</div> */}
        </div>
      );
    });
    return facilities;
  };

  return (
    <div className="main-business-detail">
      <section className="business-top-bar">
        <Container>
          <Row>
            <Col>
              <div className="business-name">
                <div className="top-bar-logo">
                  <img src="../images/logo.png" alt="logo" />
                </div>
                <div className="top-bar-download-links">
                  <a
                    target="_blank" rel="noreferrer"
                    href="https://apps.apple.com/pk/app/explore-btk/id1552549939"
                  >
                    <img src="../images/app-store.svg" />
                  </a>
                  <a
                    target="_blank" rel="noreferrer"
                    href="https://play.google.com/store/apps/details?id=com.explore.btk&hl=en_US&gl=US"
                  >
                    <img src="../images/google-play.svg" alt="google-play" />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Container>
        <Row className="business-detail-row">
          <Col lg={4}>
            <div className="business-logo">
              <div className="logo-frame">
                <img alt="business-img"
                  src={
                    businessData.data.thumbnail
                      ? businessData.data.thumbnail
                      : ""
                  }
                />
              </div>
              <div className="profile-name">
                <h4>{businessData.data.name}</h4>
              </div>

              <ul className="profile-icons">
                <li>
                </li>
                <li>
                  {
                    businessData.data.telephone ? 
                    <a href={`whatsapp://send?text=Hello World!&phone=${businessData.data.telephone}`}>
                    <FaWhatsapp />
                  </a> : null
                  }
                </li>
                <li>
                  {
                    businessData.data.website ? 
                    <a target="_blank" href={`${businessData.data.website}`} rel="noreferrer">
                    <FaChrome />
                  </a>
                  : null
                  }
                </li>
                <li>
                  {
                    businessData.data.email ? 
                    <a href={`mailto:${businessData.data.email}`}>
                    <FaEnvelope />
                  </a> : null
                  }
                </li>
                <li>
                  <a target="_blank" href="https://explorebtk.com/" rel="noreferrer">
                    <FaGooglePlay />
                  </a>
                </li>
              </ul>

              <ul className="profile-stats">
                <li>
                  Views<h6>{businessData.data.views}</h6>
                </li>
                <li>
                  Rating <h6>5</h6>
                </li>
                <li>
                  Status
                  <h6 className="status-bg">{businessData.data.status}</h6>
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={8}>
            <div className="business-cover-img">
              <img alt="cover-pic" src={coverPic ? coverPic.image : []} />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <Tabs
              defaultActiveKey="home"
              transition={false}
              id="noanim-tab-example"
              className="tabs-button-ul"
            >
              <Tab
                eventKey="home"
                title={
                  <span className="tab-span">
                    <FaList /> {"Detail"}
                  </span>
                }
              >
                <Row>
                  <Col lg={6}>
                    <div className="business-detail-div">
                      <div className="business-div">
                        <h6>Business Name</h6>
                        <div className="paragraph">
                          {businessData.data.name}
                        </div>
                      </div>

                      <div className="business-div">
                        <h6>Business Catagory</h6>
                        <div className="paragraph">
                          {businessData.data.category}
                        </div>
                      </div>

                      <div className="business-div">
                        <h6>Business Description</h6>
                        <div className="paragraph">
                          {businessData.data.description
                            ? businessData.data.description
                            : "Not Available"}
                        </div>
                      </div>

                      <div className="business-div">
                        <h6>Business Timings:</h6>
                        <div className="paragraph"> {renderBusinessTime()}</div>
                      </div>
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="business-detail-div">
                      <div className="business-div">
                        <h6>Contact No</h6>
                        <div className="paragraph">
                        {
                          businessData.data.telephone
                            ? <a href={`tel:${businessData.data.telephone}`}>
                              {businessData.data.telephone}
                            </a>
                            : "Not Available"
                            }
                        </div>
                      </div>

                      <div className="business-div">
                        <h6>Email address</h6>
                        <div className="paragraph">
                          {businessData.data.email
                            ? <a  target="_blank" href={`mailto:${businessData.data.email}`} rel="noreferrer">
                            {businessData.data.email}
                          </a>
                            : "Not Available"}
                        </div>
                      </div>

                      <div className="business-div">
                        <h6>Website</h6>
                        <div className="paragraph">
                          <a target="_blank" href={businessData.data.website
                            ? businessData.data.website
                            : "Not Available"}>
                          {businessData.data.website
                            ? businessData.data.website
                            : "Not Available"}
                          </a>
                        </div>
                      </div>

                      <div className="business-div">
                        <h6>Tags</h6>
                        <div>
                          <div className="paragraph tags">{renderTags()}</div>
                        </div>
                      </div>

                      <div className="business-div">
                        <h6>Additional Facilities</h6>
                        <div>
                          <div className="paragraph tags">
                            {renderFacilities()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Tab>

              <Tab
                eventKey="reviews"
                title={
                  <span className="tab-span">
                    <FaAward />
                    Reviews
                  </span>
                }
              >
                <Row className="tag-div reviews-main-div">
                  <h5>User Reviews:</h5>
                  <Col lg={2}>
                    {" "}
                    <div className="dumy-profile 123">
                      {renderReviewProfile()}
                    </div>{" "}
                  </Col>
                  <Col lg={10}>{renderReviews()}</Col>
                </Row>
              </Tab>

              <Tab
                eventKey="profile"
                title={
                  <span className="tab-span">
                    <FaImage />
                    {"Gallery"}
                  </span>
                }
              >
                <Row className="photo-gallery-row">
                  <GalleryImages />
                </Row>
              </Tab>

              <Tab
                eventKey="contact"
                title={
                  <span className="tab-span">
                    <FaPeriscope /> {"Address"}
                  </span>
                }
              >
                <Row>
                  <Col lg={6}>
                    <div className="business-detail-div">
                      <div className="business-div-map business-div">
                        <h6>Business Address:</h6>
                        <p>
                          {businessData.data.address
                            ? businessData.data.address
                            : "Not Available"}
                        </p>
                      </div>
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="business-detail-div">
                      <div className="business-div">
                        <GoogleMapLocation />
                      </div>
                    </div>
                  </Col>
                </Row>

                {/* <Row>
                  <Col lg={12}>
                    <div className="business-text-area">
                      <h6>Business Address:</h6>
                      {businessData.data.address}
                    </div>
                  </Col>
                  <Col lg={12}>
                    <GoogleMapLocation />
                  </Col>
                </Row> */}
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BusinessDetail;
