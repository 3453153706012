import React from "react";
import "../App.css";
import "../bootstrap.css";
import 'bootstrap/dist/css/bootstrap.min.css';

import MainSection from "./MainSection";


const Dashboard = () => {
  return (
      <div>
      <MainSection />
      </div>
  );
};

export default Dashboard;



