import React, { useState, useEffect } from "react";
import Logo from "../assets/images/logo.png";
import { useNavigate } from 'react-router-dom';
import axios from "axios";

const AuthLogin = () => {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('')

 
  useEffect(() => {
    if(localStorage.getItem('user-info')) {
     
    }
  })
  

  async function login () {
    console.log('Is Email & Password working', username, password);
    let item = {username, password};
    let result = await fetch('https://apis.explorebtk.com/api/v1/auth/login', {
      method: 'POST',
      headers: {
        'Content-type' : 'application/json',
        'Accept' : 'application/json'
      },
      body : JSON.stringify(item)
    });
    result = await result.json();
  }

  return (
      <div id="auth">
        <div className="row h-100">
          <div className="col-xs-12 col-sm-12 col-lg-6 offset-lg-3">
            <div id="auth-left">
              <div className="auth-logo">
                <a href="">
                  <img src={Logo} />
                </a>
              </div>
              <h1 className="auth-title">Log in.</h1>
              <p className="auth-subtitle mb-5">
                Log in with your data that you entered during registration.
              </p>

              <form>
                <div className="form-group position-relative has-icon-left mb-4">
                  <input
                    type="text"
                    className="form-control form-control-xl"
                    placeholder="Email"
                    onChange={(e) => {setUsername(e.target.value)}}
                  />
                  <div className="form-control-icon">
                    <i className="bi bi-person" />
                  </div>
                </div>
                <div className="form-group position-relative has-icon-left mb-4">
                  <input
                    type="password"
                    className="form-control form-control-xl"
                    placeholder="Password"
                    onChange={(e) => {setPassword(e.target.value)}}
                  />
                  <div className="form-control-icon">
                    <i className="bi bi-shield-lock"></i>
                  </div>
                </div>
                <div className="form-check form-check-lg d-flex align-items-end">
                  <input
                    className="form-check-input me-2"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label
                    className="form-check-label text-gray-600"
                    
                  >
                    Keep me logged in
                  </label>
                </div>
                <button className="btn btn-primary btn-block btn-lg shadow-lg mt-5" type="button"
                onClick={login}
                >
                  Log in
                </button>
              </form>
              <div className="text-center mt-5 text-lg fs-4">
                <p className="text-gray-600">
                  Don't have an account?{" "}
                  <a href="#" className="font-bold">
                    Sign up
                  </a>
                </p>
                <p>
                  <a className="font-bold" href="#">
                    Forgot password?
                  </a>
                </p>
              </div>
            </div>
          </div>
          
        </div>
      </div>
  );
};

export default AuthLogin;
