import React, { useState } from "react";
import "../App.css";
import "../bootstrap.css";
import Logo from "../assets/images/logo.png";
import { FaSlack, FaBuffer } from "react-icons/fa";
import { useSearchParams, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

const SideBar = () => {
  const [searchParam, setSearchParam] = useSearchParams(false);
  const [display, setDisplay] = useState(false);
  // const [displayLogo, setDisplayLogo] = useState(false);
  const [bannerHide, setBannerHide] = useState(false);

  const publicView = searchParam.get("publicView");
  console.log("USing USe Param to view", !!publicView);

  const handleClick = () => {
    // setDisplay((current) => !current);
    // setDisplayLogo(true);
    display(true);
    setBannerHide(true);
  };

  return (
    <>
      <div id="sidebar" className={publicView === "true" ? "" : "active"}>
        <div className={display ? "xyz" : "sidebar-wrapper"}>
          <div className="sidebar-header">
            <div className="d-flex justify-content-center">
              <div className="logo">
                <a href="">
                  <img src={Logo} />
                </a>
              </div>
            </div>
          </div>
          <div className="sidebar-menu">
            <ul className="menu">
              <li className="sidebar-item">
                <Link to="/dashboard" className="sidebar-link">
                  <FaSlack />
                  <span>Dashboard</span>
                </Link>
              </li>

              <li className="sidebar-item">
                <Link
                  to="/businesses"
                  className="sidebar-link"
                  onClick={handleClick}
                >
                  <FaBuffer />
                  <span>Businesses</span>
                </Link>
              </li>
            </ul>
          </div>
          <button className="sidebar-toggler btn x">
            <i data-feather="x"></i>
          </button>
        </div>
      </div>
      <div className="hide">
        <img
          style={{ width: "83%", float: "right" }}
          src="./images/banner-gif.gif"
        />
      </div>
    </>
  );
};

export default SideBar;
