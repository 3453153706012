import axios from "axios";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import React, { useState, useCallback, useEffect } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";


const GalleryImages = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [loadImage, setLoadImage] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  let { id } = useParams();
  const { isLoading, data: businessData } = useQuery("business", () => {
    return axios.get(`https://apis.explorebtk.com/api/v1/businesses/${id}`);
  });

  function getMeta(url) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => resolve(img);
      img.onerror = () => reject();
      img.src = url;
    });
  }

  const loadImagesAndGetDimentions = async () => {
    const imagesPromises = businessData.data.gallery.map((item) =>
      getMeta(item.image)
    );
    const images = await Promise.all(imagesPromises);
    const allImages = images.map((image) => ({
      src: image.currentSrc,
      width: image.naturalWidth,
      height: image.naturalHeight,
      className: "business-images-grid"
    }));
    setGalleryImages(allImages);
    setLoadImage(true);
  };

  useEffect(() => {
    loadImagesAndGetDimentions();
  }, []);

  if (isLoading) {
    return <h1>Loading...</h1>;
  }

  return (
    <>
      {loadImage ? (
        <>
          <Gallery photos={galleryImages} onClick={openLightbox} />
          <ModalGateway>
            {viewerIsOpen ? (
              <Modal onClose={closeLightbox}>
                <Carousel currentIndex={currentImage} views={galleryImages} />
              </Modal>
            ) : null}
          </ModalGateway>
        </>
      ) : (
        <h1>loading...</h1>
      )}
    </>
  );
};

export default GalleryImages;
