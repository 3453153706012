import React from "react";
import { useTable, useSortBy } from "react-table";
import { useQuery } from "react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Businesses = () => {
  const navigate = useNavigate();

  const { isLoading, data } = useQuery("businesses", () => {
    return axios.get(
      "https://apis.explorebtk.com/api/v1/businesses?recent=true"
    );
  });

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name", // accessor is the "key" in the data
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Telephone",
        accessor: "telephone",
      },
      {
        Header: "Status",
        accessor: "status",
      },
    ],
    []
  );
  // console.log("Is Data Rendering in business", data?.data);
  const tableInstance = useTable(
    { columns: columns, data: data?.data ? data?.data : [] },
    useSortBy
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  if (isLoading) {
    return <h1>Loading...</h1>;
  }
  return (
    <div className="business-section">
      <div className="business-table">
        <div className="page-heading">
          <div className="page-title">
            <div className="row">
              <div className="col-12 col-md-6 order-md-1 order-last">
                <h3>Businesses</h3>
              </div>
            </div>
          </div>
          <section className="section business-table">
            <div className="card">
              <div className="card-body">
                <table
                  className="table table-striped"
                  id="table1"
                  {...getTableProps()}
                >
                  <thead className="thead-row">
                    {
                      // Loop over the header rows
                      headerGroups.map((headerGroup) => (
                        // Apply the header row props
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            // Add the sorting props to control sorting. For this example
                            // we can add them into the header props
                            <th
                              className={
                                column.isSorted
                                  ? column.isSortedDesc
                                    ? "dataTable-table desc"
                                    : "dataTable-table asc"
                                  : ""
                              }
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              {column.render("Header")}
                              {/* Add a sort direction indicator */}
                            </th>
                          ))}
                        </tr>
                      ))
                    }
                  </thead>

                  <tbody {...getTableBodyProps()}>
                    {
                      // Loop over the table rows
                      rows.map((row, i) => {
                        // Prepare the row for display
                        prepareRow(row);
                        return (
                          // Apply the row props
                          <tr
                            {...row.getRowProps()}
                            onClick={() => {
                              navigate(`${row.original._id}?publicView=true`);
                            }}
                          >
                            {
                              // Loop over the rows cells
                              row.cells.map((cell, j) => {
                                // Apply the cell props
                                return (
                                  <td
                                    className="business-td"
                                    maxLength={5}
                                    rowSpan={cell.rowSpan}
                                    {...cell.getCellProps()}
                                  >
                                    {
                                      // Render the cell contents
                                      cell.render("Cell")
                                    }
                                  </td>
                                );
                              })
                            }
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Businesses;
