import logo from "./logo.svg";
import "./App.css";
import "./businessDetail.css";
import "./tabs.css";
import Layout from "./components/Layout";
import AuthLogin from "./components/AuthLogin";
import React, { useState } from "react";


function App() {

  const [isAuthenticated , setIsAuthenticated ] = useState(false);

  const render = () => {
    if(!isAuthenticated) {
      return <Layout />;
    } else {
     return <AuthLogin />
    }
  }


  return (
    <div className="app-section">
      
      <div>
        {render()}
      </div>

    </div>
  );
}

export default App;
