import React from "react";
import { useJsApiLoader, GoogleMap } from "@react-google-maps/api";
import { Skeleton } from "@mui/material";
import { useQuery } from "react-query";
import axios from "axios";
import { useParams } from "react-router-dom";

const GoogleMapLocation = () => {
  let { id } = useParams();

  const { isLoading, data: businessData } = useQuery("business", () => {
    return axios.get(`https://apis.explorebtk.com/api/v1/businesses/${id}`);
  });

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  if (!isLoaded) {
    return <Skeleton />;
  }
  if (isLoading) {
    return <h1>...Loading</h1>;
  }
  return (
    <div className="map-container">
      <div className="map-row">
        <GoogleMap
          center={{
            lat: businessData.data.location.coordinates[0],
            lng: businessData.data.location.coordinates[1],
          }}
          zoom={15}
          mapContainerStyle={{ width: "100%", height: "70vh" }}
        ></GoogleMap>
      </div>
    </div>
  );
};
export default GoogleMapLocation;
